<template>
  <div class="user-container">
    <Jump :jumpList="jumpList" />
    <NavMenu activeUrl="/setting/deivce" />
    <div class="user-wrap">
      <div class="user-menu">
        <chilMenu></chilMenu>
      </div>
      <div class="user-main">
        <div class="search" style="padding-left: 40px">
          <div>
            姓名：
            <el-input placeholder="请输入姓名" v-model="search.name" />
          </div>
          <div style="padding-left: 20px">
            设备编号：
            <el-input placeholder="请输入设备编号" v-model="search.deviceNo" />
          </div>
          <el-button
            class="filter-item"
            type="primary"
            size="default"
            icon="el-icon-search"
            @click="getList(page, search)"
            >搜索
          </el-button>
          <el-button class="filter-item" size="default" @click="reset"
            >清空
          </el-button>
        </div>
        <avue-crud
          ref="crud"
          :option="option"
          :data="list"
          :page.sync="page"
          v-model="form"
          :table-loading="listLoading"
          @on-load="getList"
          @size-change="sizeChange"
          @current-change="currentChange"
          @row-update="update"
          @row-save="create"
          :before-open="beforeOpen"
          @selection-change="selectionChange"
        >
          <template slot="deptIdForm">
            <cascader
              v-model="form.deptId"
              :name.sync="form.deptName"
              :options="depData"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                emitPath: false,
              }"
            ></cascader>
          </template>
          <template slot="menuLeft">
            <el-button
              type="primary"
              size="small"
              @click="$refs.crud.rowAdd()"
              icon="el-icon-plus"
              v-auth="['electron_person_add']"
            >
              新增
            </el-button>
            <!--<el-button type="primary" size="small"> 附件导入 </el-button>-->
          </template>
          <template slot="menuRight">
            <el-button type="primary" @click="exportXlsx()" size="small">
              导出
            </el-button>
          </template>

          <template slot="menu" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="$refs.crud.rowEdit(scope.row, scope.index)"
              icon="el-icon-edit"
              >编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="$refs.detail.open(scope.row)"
              icon="el-icon-view"
              >详情
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="delRow(scope.row)"
              >删除
            </el-button>
          </template>
          <template slot="personList" slot-scope="{ row }">
            <span v-for="(item, index) in row.personList" :key="index">
              {{ item.name }}
              <!-- <span v-if="index !== row.roleList.length - 1">,</span> -->
            </span>
          </template>
          <template slot="personListForm">
            <el-select
              multiple
              v-model="form.personList"
              placeholder="请选择关联用户"
              value-key="personId"
              collapse-tags
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </avue-crud>
      </div>
    </div>
    <detail ref="detail" />
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../setting/navMenu.vue";
import chilMenu from "../app/chilMenu.vue";
import detail from "./detail.vue";
import { tableOption } from "./tableOption";
import { exportXlsx } from "@/components/processingXlsx";
import outOption from "./outOption";
import {
  eleParentPage,
  addEleParent,
  updateEleParent,
  delEleParent,
} from "../../api/guardian";
import { electronPersonPage } from "../../api/elec/user";
import { encryption } from "@/utils/util.js";
// import { electronDevicePage } from "../../../../api/elec/device";
import { deptTreeByScope } from "../../api/deviceManagement";
export default {
  name: "user",
  data() {
    return {
      activeUrl: "elecUser",
      outOption,
      jumpList: [
        { name: "首页" },
        { name: "系统管理" },
        { name: "应用管理" },
        { name: "电子设备" },
        { name: "监护人" },
      ],
      admineleclist: null, //电子设备管理
      elecrolelist: null,
      elecwarnlist: null,
      elecuserlist: null,
      elecclocklist: null,
      search: {},
      depData: [],
      listLoading: false,
      option: tableOption,
      form: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false, // 是否倒序
      },
      list: [],
      roleList: [],
      selection: [],
      // deviceList:[]
    };
  },
  computed: {
    exportData() {
      const data = JSON.parse(JSON.stringify(this.selection));
      return data.map((item) => {
        item.personList = item.personList
          .map((item) => {
            return item.name;
          })
          .join(",");
        console.log(item.personList);
        return item;
      });
    },
  },
  created() {
    this.getRoleList();
    // this.getDeviceList();
    this.getDep();
  },
  mounted() {
    let permissions = this.$store.state.app.permissions;
    permissions.forEach((item) => {
      if (item === "electron_device_view") {
        this.admineleclist = 1;
      }
      if (item === "electron_alert_record_view") {
        this.elecwarnlist = 1;
      }
      if (item === "electron_punch_record_view") {
        this.elecclocklist = 1;
      }
      if (item === "electron_role_view") {
        this.elecrolelist = 1;
      }
      if (item === "electron_person_view") {
        this.elecuserlist = 1;
      }
    });
  },
  methods: {
    async getDep() {
      const res = await deptTreeByScope();
      this.depData = res.data;
    },
    async update(form, index, done, loading) {
      console.log(form);
      let obj = {
        deptId: form.deptId,
        deptName: form.deptName,
        id: form.id,
        nickname: form.nickname,
        oldPassword: form.password,
        password: form.passwordNew,
        personList: form.personList,
        username: form.username,
      };
      const form1 = encryption({
        data: obj,
        key: "wertwertwertwert",
        param: ["password"],
      });
      if (!form1.password) {
        delete form1.password;
      }
      const res = await updateEleParent(form1);
      if (res.code === 0) {
        this.$message.success("编辑成功");
        await this.getList();
        done();
      } else {
        this.$message.warning(res.msg);
        loading();
      }
    },
    async create(form, done, loading) {
      const form1 = encryption({
        data: form,
        key: "wertwertwertwert",
        param: ["password"],
      });
      if (!form1.password) {
        delete form1.password;
      }
      const res = await addEleParent(form1);
      if (res.code === 0) {
        this.$message.success("新增成功");
        done();
      } else {
        this.$message.warning(res.msg);
        loading();
      }
      await this.getList();
    },
    beforeOpen(done,type){
      console.log(type)
      if(type==='add'){
        this.findObject(this.option.column,'password').rules=[
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ];
        this.findObject(this.option.column,'passwordNew').rules=[
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ];
      }else{
        this.findObject(this.option.column,'password').rules=[]
        this.findObject(this.option.column,'passwordNew').rules=[]
      }
      done();
    },
    async delRow(row) {
      console.log(row);
      this.$confirm("是否确认删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delEleParent(row.id);
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getList();
        }
      });
    },
    async exportXlsx() {
      if (!this.selection.length) return this.$message.warning("请选择数据");
      this.exportLoading = true;
      const data = this.exportData;
      const res = await exportXlsx(data, this.outOption).finally(
        () => (this.exportLoading = false)
      );
      console.log(res);
      this.$message.success("导出成功！");
    },
    selectionChange(val) {
      this.selection = val;
    },
    tabClick(url) {
      this.$router.push(url);
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList();
    },
    reset() {
      this.search = {};
      this.page.currentPage = 1;
    },
    async getRoleList() {
      const res = await electronPersonPage({ size: -1 });
      this.roleList = res.data.records;
      this.roleList.forEach((item) => {
        item.personId = item.id;
      });
    },
    async getDeviceList() {
      const res = await electronDevicePage({ size: -1 });
      this.findObject(this.option.column, "deviceNo").dicData =
        res.data.records.map((item) => {
          return {
            label: item.deviceNo,
            value: item.deviceNo,
          };
        });
    },
    async getList() {
      const res = await eleParentPage({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...this.search,
      });
      this.list = res.data.records;
      this.page.total = res.data.total;
    },
  },
  components: {
    Jump,
    NavMenu,
    detail,
    chilMenu,
  },
};
</script>
<style lang="scss">
.el-menu-e {
  height: 670px;
}

.user-container {
  .user-wrap {
    display: flex;
    .user-menu {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
    .user-main {
      background: transparent;
    }
  }

  .user {
    height: 100%;
    &__main {
      .el-card_body {
        padding-top: 0;
      }
    }
  }
  .el-card {
    background-color: transparent;
    border: none;
  }

  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.search {
  display: flex;
  margin-top: 15px;
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    margin-right: 10px;
    .el-range-input {
      background-color: #06193c;
      color: rgba(163, 214, 255, 0.36) !important;
    }
  }
  .el-date-editor .el-range-separator {
    color: rgba(163, 214, 255, 0.76);
  }
  .el-date-editor .el-range__icon {
    color: rgba(163, 214, 255, 0.76);
  }
}

.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
}
::v-deep .el-dialog__body {
  ::-webkit-scrollbar {
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #0a4696;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: #0c265a;
  }
}
</style>
