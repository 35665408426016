const outOption = {
  title: "监护人",
  column: [
    {
      label: "姓名",
      prop: "nickname",
    },
    {
      label: "联系电话(账号）",
      prop: "username",
    },
    {
      label: "用户姓名",
      prop: "personList",
    },
    {
      label: '所属部门',
      prop: 'deptName',
    },
  ],
};
export default outOption
