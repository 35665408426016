<template>
  <el-dialog title="详情" width="40%" :visible.sync="dialogFormVisible">
    <el-descriptions class="margin-top" :column="2" border>
      <el-descriptions-item>
        <template slot="label"> 姓名 </template>
        {{ form.nickname }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 联系电话(账号） </template>
        {{ form.username }}
      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label"> 所属部门 </template>
        {{ form.deptName }}
      </el-descriptions-item>
      <template v-for="(item, index) in form.personList">
        <el-descriptions-item :key="item.name+index">
          <template slot="label"> 用户姓名{{index+1}} </template>
          <span>
          {{ item.name }}
        </span>
        </el-descriptions-item>
        <el-descriptions-item :key="item.deviceNo+index">
          <template slot="label"> 设备编号{{index+1}} </template>
          <span>
          {{ item.deviceNo }}
        </span>
        </el-descriptions-item>
      </template>
      <!-- <el-descriptions-item>
        <template slot="label"> 联系电话 </template>
        {{ form.mobile }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 紧急联系人1 </template>
        {{ form.emergencyContact1 }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 紧急联系人电话1 </template>
        {{ form.emergencyContactNumber1 }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 紧急联系人2 </template>
        {{ form.emergencyContact2 }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 紧急联系人电话2 </template>
        {{ form.emergencyContactNumber2 }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 住址 </template>
        {{ form.residence }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备编号 </template>
        {{ form.deviceNo }}
      </el-descriptions-item> -->
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {},
    };
  },

  methods: {
    open(row) {
      this.form = row;
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style lang="scss">
.el-form-item__label {
  color: #a3d6ff;
}
.el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.el-dialog__header {
  background: url("../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.el-dialog__body {
  background: #0c265a;
}
.el-dialog__footer {
  background: #0c265a;
  border: none !important;
}
</style>
