import request from '@/utils/request'

// 获取监护人列表
export function eleParentPage(data) {
    return request({
      url: '/admin/eleParent/eleParentPage',
      method: 'get',
      params: data
    })
  }

  // 新增监护人
  export function addEleParent(data) {
    return request({
      url: '/admin/eleParent/addEleParent',
      method: 'post',
      data
    })
  }

// 编辑
  export function updateEleParent(data) {
    return request({
      url: '/admin/eleParent/updateEleParent',
      method: 'post',
      data
    })
  }


  export function delEleParent(id) {
    return request({
      url: `/admin/eleParent/delEleParent/${id}`,
      method: 'delete',
    })
  }