/* 是否手机号码*/
function validatePhone(rule, value, callback) {
  // const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  const reg =
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  if (value === "" || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== "") {
      callback(new Error("请输入正确的电话号码"));
    } else {
      callback();
    }
  }
}
export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: "center",
  selection: true,
  tip: false,
  labelWidth: 140,
  searchMenuSpan: 6,
  align: "center",
  editBtn: false,
  delBtn: false,
  addBtn: false,
  column: [
    {
      fixed: true,
      label: "姓名",
      prop: "nickname",
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "联系电话(账号）",
      prop: "username",
      span: 24,
      rules: [
        { validator: validatePhone, trigger: ["blur", "change"] },
        {
          required: true,
          message: "请输入联系电话",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "密码",
      span:24,
      hide:true,
      prop: "password",
      // editDisplay: false,
      rules: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "新密码",
      span:24,
      hide:true,
      prop: "passwordNew",
      addDisplay: false,
      rules: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "用户姓名",
      span:24,
      prop: "personList",
      rules: [
        {
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        },
      ],
    },

    {
      label: '所属部门',
      prop: 'deptId',
      hide: true,
      rules: [{
        required: true,
        message: "请选择所属部门",
        trigger: "blur"
      }]
    },
    {
      label: '所属部门',
      prop: 'deptName',
      addDisplay: false,
      editDisplay: false,
    },

  ],
};
